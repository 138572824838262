import React, { useState, useEffect } from "react";
import { useMediaQuery } from "@react-hook/media-query";
import {
	CommonLines,
	Link,
	Separator,
	Text,
	InputWithButton,
	Button,
	HorizontalList,
	CloudinaryImage,
	Icon,
	Rating,
	CallUsModal,
	StarRatingWithLogo,
} from "@website-builder/ui/shared/elements";
import { AlumniBar } from "@website-builder/ui/shared/components";
import {
	TwoColorWrapper,
	MainSection,
	SubSection,
	CareerQuizWrapper,
	InputWithButtonWrapper,
	Flow,
	LinksWrapper,
	PhoneEmailContainer,
} from "./styles";
import { handleCTAClick } from "@website-builder/utilities/utils/DesignUtils.js";
import { addToDataLayer } from "@website-builder/utilities/utils/utils.js";
import {
	TABLET_M_BP,
	DESKTOP_L_BP,
} from "@website-builder/utilities/constants/style-constants/screen-sizes.js";

const CTABlock = (props) => {
	const {
		color,
		lineGraphic,
		heading,
		leftSection,
		rightSection,
		pageSection = "",
		context,
		workshopId = "",
		starRatingWithLogo,
	} = props;
	const linkColorStyle = { color: "var(--secondary-purple-300)" };
	const breakPoint = useMediaQuery(`(max-width: ${TABLET_M_BP}px)`);
	const [isScreenAbove1400, setIsScreenAbove1400] = useState(undefined);
	const widthOver1400 = useMediaQuery(`(min-width: ${DESKTOP_L_BP}px)`);
	const [showCallUsModal, setShowCallUsModal] = useState(false);
	const [isSuccess, setIsSuccess] = useState(false);

	useEffect(() => {
		setIsScreenAbove1400(widthOver1400);
	}, [widthOver1400]);

	const handlePhoneEmailCtaClick = (event) => {
		event.preventDefault();
		setShowCallUsModal(true);
	};

	const handleClick = (cta, clickEvent) => {
		let eventData = {
			page_section: pageSection,
			redirect_to: cta?.link?.link,
			click_text: cta?.linkText || cta?.buttonText,
		};
		handleCTAClick(cta, eventData, { context }, clickEvent);
	};
	const handleSuccessOfCTA = (waitlist) => {
		let referrerIdentifier = localStorage.getItem("referrer_identifier");
		let referralCode = localStorage.getItem("referral_code");
		if (referralCode && referrerIdentifier) {
			addToDataLayer({
				event: "friend_referral_code_apply",
				referrer_identifier: referrerIdentifier,
				referral_code: referralCode,
			});
		}

		if (waitlist) {
			setIsSuccess(true);
			return waitlist?.redirectAfterSuccess;
		}
	};

	const itemBuilder = (item) => {
		switch (item?.component) {
			case "textWR":
				return (
					<Text
						variant="paragraph_L"
						tag="div"
						dangerouslySetInnerHTML={{ __html: item?.text }}
					/>
				);
			case "careerQuizCta":
				return (
					<React.Fragment>
						<Separator
							opacity="1"
							color="var(--primary-neutral-nightshade-600)"
						/>
						<CareerQuizWrapper>
							<Text variant="paragraph_L">{item.label}</Text>
							<Link
								arrowCta
								href={item?.cta?.link?.link}
								target={item?.cta?.link?.target}
								style={linkColorStyle}
								onClick={(e) => handleClick(item?.cta, e)}
							>
								{item.linkText}
							</Link>
						</CareerQuizWrapper>
					</React.Fragment>
				);

			case "emailInputWithCTAandText":
				const hasWaitlist = Object.keys(item.waitlist).length > 0;
				const inputId = "cta-block-apply-now-email-input";
				// Change onClick function with button events- add validation and trigger required APIs
				return (
					<InputWithButtonWrapper>
						{!(isSuccess && hasWaitlist) ? (
							<>
								<Text for={inputId} variant="label_M" className="required">
									{item.label}
								</Text>
								<InputWithButton
									inputType="email"
									inputId={inputId}
									placeholder={item.placeholder}
									buttonText={item.buttonText}
									buttonEvent={item.buttonEvent}
									onSuccess={() =>
										handleSuccessOfCTA(hasWaitlist ? item?.waitlist : false)
									}
									context={context}
									workshopId={workshopId}
									pageSection={pageSection}
								/>
							</>
						) : (
							<div className="waitlist-success-msg-wrapper">
								<Icon
									className="icon-waitlist"
									iconName={"checkCircle"}
									stroke={"var(--primary-forest-400)"}
									size={"1.125rem"}
								/>
								<Text variant="paragraph_S_bold">
									{item?.waitlist?.successMessage}{" "}
								</Text>
							</div>
						)}
						{item.text ? (
							<Text
								variant="paragraph_S"
								className="dangerously-set-text"
								tag="div"
								dangerouslySetInnerHTML={{ __html: item.text }}
							/>
						) : null}
					</InputWithButtonWrapper>
				);

			case "textWithArrowCta":
				return (
					<Flow gap="0.5rem">
						<Text variant="paragraph_M">{item.text}</Text>
						<Link
							arrowCta
							href={item?.cta?.link?.link}
							target={item?.cta?.link?.target}
							style={linkColorStyle}
							onClick={(e) => handleClick(item?.cta, e)}
						>
							{item.linkText}
						</Link>
					</Flow>
				);

			case "AlumniBar":
				const listItems = item?.listItems?.map((list) => ({
					...list,
					alt: "",
				}));
				return <AlumniBar {...item} listItems={listItems} />;

			case "linksWithSeparatorCta":
				return (
					<React.Fragment>
						<Separator
							opacity="1"
							color="var(--primary-neutral-nightshade-600)"
						/>
						<LinksWrapper>
							{item.links?.map((link, index) => {
								return (
									<Link
										key={index}
										arrowCta={link.arrowCta}
										onClick={(e) => handleClick(link?.cta, e)}
										iconConfig={link.iconConfig}
										href={link?.link?.link}
										target={link?.link?.target}
										iconRight={link.iconRight}
										disabled={link.disabled}
										style={linkColorStyle}
									>
										{link.linkText}
									</Link>
								);
							})}
						</LinksWrapper>
					</React.Fragment>
				);

			case "ButtonWR":
				return (
					<Button
						{...item}
						type={item.type}
						buttonText={item.buttonText}
						onClick={() => handleClick(item)}
					/>
				);

			case "brandBarSimple":
				return (
					<HorizontalList
						listItems={item.brandLogos?.map((logo, i) => {
							if (logo?.image?.image) {
								return (
									<CloudinaryImage
										className="cta-block-logos"
										key={`logo-image-${i}`}
										url={logo?.image?.image}
										quality="auto"
										lazyload
										fetchFormat="auto"
										sizes={{
											default: {
												height: 20,
											},
										}}
										alt={logo?.image?.altTag}
									/>
								);
							}
						})}
						maxItems={parseInt(item.maxLogosInRow, 10)}
						labelConfig={{
							text: item.labelText,
						}}
						gap="30px"
					/>
				);

			case "phoneEmailCta":
				return (
					<PhoneEmailContainer>
						<Link
							iconConfig={{ iconName: "call" }}
							href={`tel:${item.contactNo}`}
							onClick={handlePhoneEmailCtaClick}
							as="button"
							aria-expanded={showCallUsModal}
							aria-controls={showCallUsModal ? "cta-block-call-us-modal" : null}
							aria-haspopup="dialog"
						>
							{item.contactNo}
						</Link>
						<CallUsModal
							showModal={showCallUsModal}
							onClose={() => setShowCallUsModal(false)}
							tel={item.contactNo}
							imageUrl={item?.imageUrl}
							imageAltText={item?.imageAltText}
							upperText={item?.upperText}
							lowerText={item?.lowerText}
							id="cta-block-call-us-modal"
						/>
						<Separator
							vertical
							opacity="1"
							color="var(--primary-neutral-nightshade-600)"
						/>
						<Link
							iconConfig={{ iconName: "email" }}
							href={`mailTo:${item.email}`}
						>
							{item.email}
						</Link>
					</PhoneEmailContainer>
				);

			case "Rating":
				return <Rating ratings={item.ratings} />;

			default:
				return <></>;
		}
	};

	return (
		<TwoColorWrapper className="cta-block">
			<MainSection color={color.bg}>
				<SubSection>
					<Text variant="headline_3" tag="h2">
						{heading}
					</Text>
					{leftSection.map((item, index) => {
						return (
							<React.Fragment key={index}>{itemBuilder(item)}</React.Fragment>
						);
					})}
				</SubSection>
				<SubSection>
					{rightSection.map((item, index) => {
						return (
							<React.Fragment key={index}>{itemBuilder(item)}</React.Fragment>
						);
					})}
					{starRatingWithLogo && Object.keys(starRatingWithLogo).length > 0 && (
						<>
							{rightSection?.length > 0 && (
								<Separator
									opacity="1"
									color="var(--primary-neutral-nightshade-600)"
								/>
							)}
							<StarRatingWithLogo
								starRatingWithLogo={starRatingWithLogo}
							></StarRatingWithLogo>
						</>
					)}
				</SubSection>
				{lineGraphic === "left" ? (
					<CommonLines
						variant="7"
						stroke={color.line}
						size={breakPoint ? "25vw" : isScreenAbove1400 ? "15vw" : "20vw"}
					/>
				) : (
					<></>
				)}
				{lineGraphic === "right" ? (
					<CommonLines
						variant="6"
						stroke={color.line}
						size={breakPoint ? "25vw" : isScreenAbove1400 ? "15vw" : "20vw"}
					/>
				) : (
					<></>
				)}
			</MainSection>
		</TwoColorWrapper>
	);
};

export default CTABlock;
